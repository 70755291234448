<template>
  <div>
    <!-- :style="{ backgroundImage: `url('${getImgUrl(baseImg)}')` }" -->
    <div class="card">
      <div class="card1" id="sts-card-base-img">
        <img
          v-if="!imageOnly"
          class="base"
          :src="getImgUrl(baseImg)"
          alt=""
        />
        <img class="cardimg" :class="{ cardimgonly: imageOnly }" :src="getImgUrl(imageImg)" alt="" loading="lazy" />
        <img class="frame" :class="{ frameonly: imageOnly }" :src="getImgUrl(frameImg)" alt="" />
        <p class="type" :style="{ fontSize: typeRelativeFontSize }" v-if="!imageOnly">
          {{ type }}
        </p>
        <img class="banner" :class="{ banneronly: imageOnly }" :src="getImgUrl(bannerImg)" alt="" />
        <p
          v-if="!displayPlusData && !imageOnly"
          class="title"
          :style="{ fontSize: titleRelativeFontSize }"
        >
          {{ title }}
        </p>
        <p
          v-if="displayPlusData"
          class="title title-plus"
          :style="{ fontSize: titleRelativeFontSize }"
        >
          {{ title }}+
        </p>
        <div class="mana" v-if="manaShowMana && !imageOnly">
          <div style="position: relative">
            <img :src="getImgUrl(manaImg)" alt="" />
            <!-- Mana -->
            <p
              v-if="!displayPlusData && mana >= 0"
              class="mana-text"
              :style="{ fontSize: manaRelativeFontSize }"
            >
              {{ mana }}
            </p>
            <p
              v-if="!displayPlusData && mana === -1"
              class="mana-text"
              :style="{ fontSize: manaRelativeFontSize }"
            >
              X
            </p>
            <!-- Mana Plus -->
            <p
              v-if="displayPlusData && mana >= 0"
              class="mana-text"
              :style="{ fontSize: manaRelativeFontSize }"
            >
              <span
                :class="{ 'sts-card-desc-font-color-green': manaPlusCostLess }"
                >{{ manaplus }}</span
              >
            </p>
            <p
              v-if="displayPlusData && mana === -1"
              class="mana-text"
              :style="{ fontSize: manaRelativeFontSize }"
            >
              X
            </p>
          </div>
        </div>
        <div
          v-if="tier !== false && !imageOnly"
          class="tier"
          :class="{
            'tier-s': tier.includes('S'),
            'tier-a': tier.includes('A'),
            'tier-b': tier.includes('B'),
            'tier-c': tier.includes('C'),
            'tier-d': tier.includes('D'),
            'tier-e': tier.includes('E'),
            'tier-f': tier.includes('F'),
            'tier-u': tier.includes('U'), // yet to be graded
          }"
        >
          <p v-if="tier !== 'U'" :style="{ fontSize: tierRelativeFontSize }">
            {{ tier }}
          </p>
          <p v-else :style="{ fontSize: tierRelativeFontSize }">?</p>
        </div>
        <div class="description" v-if="!imageOnly">
          <p
            v-html="descriptionParse"
            :style="{ fontSize: descriptionRelativeFontSize }"
          >
            {{ descriptionParse }}
          </p>
        </div>
        <div
          v-if="descriptionplus !== '' && detail === true"
          class="plus"
          @mouseenter="plusHover = true"
          v-on:mouseleave="plusHover = false"
        >
          <!-- <div class="plus2">
            <p>PLUS</p>
          </div> -->
          <img
            v-if="!plusHover"
            src="./../assets/cards/plus/noplus.png"
            alt=""
          />
          <img v-if="plusHover" src="./../assets/cards/plus/plus.png" alt="" />
        </div>
        <div
          v-if="$route.path === '/tools/slaythespire/loadout'"
          class="card-button"
        >
          <div v-if="browser" @click.stop="addCardToDeck(title, false)">
            <p>Add Card</p>
          </div>
          <div
            v-if="browser && descriptionplus !== ''"
            @click.stop="addCardToDeck(title, true)"
          >
            <p>Add Card+</p>
          </div>
          <div
            v-if="!browser && descriptionplus !== '' && !plus"
            @click.stop="upgradeCard()"
          >
            <p>Upgrade</p>
          </div>
          <div
            v-if="!browser && descriptionplus !== '' && plus"
            @click.stop="downgradeCard()"
          >
            <p>Downgrade</p>
          </div>
          <div v-if="!browser" @click.stop="removeCard()"><p>Remove</p></div>
        </div>
        <div class="card-button compare-add">
          <div
            v-if="!browser && comparator"
            @click.stop="addCardToDeck(title, plus)"
          >
            Add Card
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    type: {
      type: String,
      required: true,
    },
    rarity: {
      type: String,
      required: true,
      validator: (value) => {
        return ['common', 'uncommon', 'rare', 'starter'].indexOf(value) !== -1;
      },
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    descriptionplus: {
      type: String,
      required: true,
    },
    character: {
      type: String,
      required: true,
      validator: (value) => {
        return (
          [
            'defect',
            'ironclad',
            'silent',
            'watcher',
            'colorless',
            'curse',
          ].indexOf(value) !== -1
        );
      },
    },
    mana: {
      type: Number,
      required: true,
    },
    manaplus: {
      type: Number,
      required: true,
    },
    tier: {
      type: String,
      // required: true,
      default: false,
    },
    highlightDesc: {
      type: Boolean,
      default: false,
    },
    highlightTextColor: {
      type: String,
      default: 'green',
    },
    plus: {
      type: Boolean,
      default: false,
    },
    comparator: {
      // Purely to prevent showing add card button on anything but the comparator
      type: Boolean,
      default: false,
    },
    browser: {
      // if cards are in the browser (true) / mydeck, comparator cards (false)
      type: Boolean,
      default: true,
    },
    detail: {
      // to hide plus hover states (eg. in side by side)
      type: Boolean,
      default: true,
    },
    mydeckIndex: {
      // if cards are in the browser (true) / mydeck, comparator cards (false)
      type: Number,
      // default: true,
    },
    mydeck: {
      type: Array,
    },
    beta: {
      // temp image display for missing cards
      type: Boolean,
    },
    elementIdForWidth: {
      type: String,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    mod: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      cardWidthPx: 0,
      plusHover: false,
      //plus: false,
    };
  },
  computed: {
    manaPlusCostLess() {
      if (this.manaplus < this.mana) {
        return true;
      }
      return false;
    },
    manaShowMana() {
      if (this.mana > -2) {
        return true;
      }
      return false;
    },
    displayPlusData() {
      if (this.plus || this.plusHover) {
        return true;
      }
      return false;
    },
    rarityModify() {
      if (this.rarity === 'starter') {
        return 'common';
      }
      return this.rarity;
    },
    // manaDescImgStyles(){
    //   return {
    //     img: {
    //       width: this.manaRelativeFontSize,
    //     }
    //   }
    // },
    imageImg() {
      const camelised = this.camelise(this.title);
      // if (this.brokenName(camelised).broken){
      //   camelised = this.brokenName(camelised).fix;
      // }
      if (this.mod === true) {
        // for modded cards show ? image
        return `cards/image/curse/modCardImage`;
      }
      if (this.type === 'curse') {
        return `cards/image/curse/${camelised}`;
      }
      if (this.beta === true) {
        // Beta (card image missing check)
        return `cards/image/${this.character}/${this.type}/1no-image`;
      }
      return `cards/image/${this.character}/${this.type}/${camelised}`;
    },
    bannerImg() {
      return `cards/rarity/${this.rarityModify}/banner`;
    },
    frameImg() {
      if (this.type === 'curse') {
        return `cards/rarity/${this.rarityModify}/frame/skill`;
      }
      return `cards/rarity/${this.rarityModify}/frame/${this.type}`;
    },
    baseImg() {
      if (this.type === 'curse') {
        return `cards/base/${this.character}/skill`;
      }
      return `cards/base/${this.character}/${this.type}`;
    },
    manaImg() {
      // fix for curse card mana (even though not shown)
      if (this.character === 'curse') {
        return `cards/mana/colorless`;
      }
      return `cards/mana/${this.character}`;
    },
    titleRelativeFontSize() {
      const baseFont = 36;
      const baseCardWidth = 504;
      const calc = (this.cardWidthPx / baseCardWidth) * baseFont;
      return calc + 'px';
    },
    descriptionRelativeFontSize() {
      const baseFont = 32.5;
      const baseCardWidth = 470;
      const calc = (this.cardWidthPx / baseCardWidth) * baseFont;
      return calc + 'px';
    },
    manaRelativeFontSize() {
      const baseFont = 56;
      const baseCardWidth = 504;
      const calc = (this.cardWidthPx / baseCardWidth) * baseFont;
      return calc + 'px';
    },
    typeRelativeFontSize() {
      const baseFont = 24;
      const baseCardWidth = 504;
      const calc = (this.cardWidthPx / baseCardWidth) * baseFont;
      return calc + 'px';
    },
    tierRelativeFontSize() {
      const baseFont = 24;
      const baseCardWidth = 228;
      const calc = (this.cardWidthPx / baseCardWidth) * baseFont;
      return calc + 'px';
    },
    descriptionParse() {
      if (this.description === '' || this.description === undefined) {
        return;
      }
      // Key words
      let desc = this.displayPlusData === true ? this.descriptionplus : this.description;
      desc = desc.replace(
        /Artifact/g,
        '<span class="sts-card-desc-font-color-gold">Artifact</span>',
      );
      desc = desc.replace(
        /Beta/g,
        '<span class="sts-card-desc-font-color-gold">Beta</span>',
      );
      desc = desc.replace(
        /Burn/g,
        '<span class="sts-card-desc-font-color-gold">Burn</span>',
      );
      desc = desc.replace(
        /Block/g,
        '<span class="sts-card-desc-font-color-gold">Block</span>',
      );
      desc = desc.replace(
        /Calm/g,
        '<span class="sts-card-desc-font-color-gold">Calm</span>',
      );
      desc = desc.replace(
        /Causality/g,
        '<span class="sts-card-desc-font-color-gold">Causality</span>',
      );
      desc = desc.replace(
        /Channeled/g,
        '<span class="sts-card-desc-font-color-gold">Channeled</span>',
      );
      desc = desc.replace(
        /Channel/g,
        '<span class="sts-card-desc-font-color-gold">Channel</span>',
      );
      desc = desc.replace(
        /Dark/g,
        '<span class="sts-card-desc-font-color-gold">Dark</span>',
      );
      desc = desc.replace(
        /Dazed/g,
        '<span class="sts-card-desc-font-color-gold">Dazed</span>',
      );
      desc = desc.replace(
        /Dexterity/g,
        '<span class="sts-card-desc-font-color-gold">Dexterity</span>',
      );
      desc = desc.replace(
        /Discard/g,
        '<span class="sts-card-desc-font-color-gold">Discard</span>',
      );
      desc = desc.replace(
        /Exhausted\b/g,
        '<span class="sts-card-desc-font-color-gold">Exhausted</span>',
      );
      // Exhausted first!
      desc = desc.replace(
        /Exhaust\b/g,
        '<span class="sts-card-desc-font-color-gold">Exhaust</span>',
      );
      desc = desc.replace(
        /Evoked/g,
        '<span class="sts-card-desc-font-color-gold">Evoked</span>',
      );
      desc = desc.replace(
        /Evoke/g,
        '<span class="sts-card-desc-font-color-gold">Evoke</span>',
      );
      desc = desc.replace(
        /Fatal/g,
        '<span class="sts-card-desc-font-color-gold">Fatal</span>',
      );
      desc = desc.replace(
        /Focus/g,
        '<span class="sts-card-desc-font-color-gold">Focus</span>',
      );
      desc = desc.replace(
        /Frost/g,
        '<span class="sts-card-desc-font-color-gold">Frost</span>',
      );
      desc = desc.replace(
        /Innate/g,
        '<span class="sts-card-desc-font-color-gold">Innate</span>',
      );
      desc = desc.replace(
        /Insight/g,
        '<span class="sts-card-desc-font-color-gold">Insight</span>',
      );
      desc = desc.replace(
        /Intangible/g,
        '<span class="sts-card-desc-font-color-gold">Intangible</span>',
      );
      desc = desc.replace(
        /Lightning/g,
        '<span class="sts-card-desc-font-color-gold">Lightning</span>',
      );
      desc = desc.replace(
        /Mark/g,
        '<span class="sts-card-desc-font-color-gold">Mark</span>',
      );
      desc = desc.replace(
        /Miracles/g,
        '<span class="sts-card-desc-font-color-gold">Miracles</span>',
      );
      desc = desc.replace(
        /Omega/g,
        '<span class="sts-card-desc-font-color-gold">Omega</span>',
      );
      desc = desc.replace(
        /Plasma/g,
        '<span class="sts-card-desc-font-color-gold">Plasma</span>',
      );
      desc = desc.replace(
        /Poisoned\b/g,
        '<span class="sts-card-desc-font-color-gold">Poisoned</span>',
      );
      desc = desc.replace(
        /Poison\b/g,
        '<span class="sts-card-desc-font-color-gold">Poison</span>',
      );
      desc = desc.replace(
        /Retained/g,
        '<span class="sts-card-desc-font-color-gold">Retained</span>',
      );
      desc = desc.replace(
        /Retain/g,
        '<span class="sts-card-desc-font-color-gold">Retain</span>',
      );
      desc = desc.replace(
        /Safety\b/g,
        '<span class="sts-card-desc-font-color-gold">Safety</span>',
      );
      desc = desc.replace(
        /Shivs\b/g,
        '<span class="sts-card-desc-font-color-gold">Shivs</span>',
      );
      desc = desc.replace(
        /Shiv\b/g,
        '<span class="sts-card-desc-font-color-gold">Shiv</span>',
      );
      desc = desc.replace(
        /Scry\b/g,
        '<span class="sts-card-desc-font-color-gold">Scry</span>',
      );
      desc = desc.replace(
        /Smites\b/g,
        '<span class="sts-card-desc-font-color-gold">Smites</span>',
      );
      desc = desc.replace(
        /Smite\b/g,
        '<span class="sts-card-desc-font-color-gold">Smite</span>',
      );
      desc = desc.replace(
        /Stance\b/g,
        '<span class="sts-card-desc-font-color-gold">Stance</span>',
      );
      desc = desc.replace(
        /Strength\b/g,
        '<span class="sts-card-desc-font-color-gold">Strength</span>',
      );
      desc = desc.replace(
        /Through Violence\b/g,
        '<span class="sts-card-desc-font-color-gold">Through Violence</span>',
      );
      desc = desc.replace(
        /Truth\b/g,
        '<span class="sts-card-desc-font-color-gold">Truth</span>',
      );
      desc = desc.replace(
        /Weak\b/g,
        '<span class="sts-card-desc-font-color-gold">Weak</span>',
      );
      desc = desc.replace(
        /Wisdom\b/g,
        '<span class="sts-card-desc-font-color-gold">Wisdom</span>',
      );
      desc = desc.replace(
        /Wrath\b/g,
        '<span class="sts-card-desc-font-color-gold">Wrath</span>',
      );
      desc = desc.replace(
        /Upgraded\b/g,
        '<span class="sts-card-desc-font-color-gold">Upgraded</span>',
      );
      // Upgraded First
      desc = desc.replace(
        /Upgrade\b/g,
        '<span class="sts-card-desc-font-color-gold">Upgrade</span>',
      );
      desc = desc.replace(
        /Void/g,
        '<span class="sts-card-desc-font-color-gold">Void</span>',
      );
      desc = desc.replace(
        /Vulnerable/g,
        '<span class="sts-card-desc-font-color-gold">Vulnerable</span>',
      );
      desc = desc.replace(
        /Weak/g,
        '<span class="sts-card-desc-font-color-gold">Weak</span>',
      );

      // Energy images = [energy]

      desc = desc.replace(
        /(\[energy\])/g,
        `<img class="" src="${this.getImgUrl(this.manaImg)}" style="width: ${
          this.descriptionRelativeFontSize
        }; height: ${
          this.descriptionRelativeFontSize
        }; display:inline;" alt="">`,
      );
      // Highlighters
      if (this.highlightDesc || this.plusHover) {
        const highlightColor =
          this.highlightTextColor === 'green' ? 'green' : 'red';
        desc = desc.replace(
          /\^([\w+]+)/g,
          `<span class="sts-card-desc-font-color-${highlightColor}">$&</span>`,
        );
        // this changes a gold word green for outliers where a key word is added to a card
        // must be after the spans
        desc = desc.replace(
          /\^(<span class="sts-card-desc-font-color-gold">)/g,
          `<span class="sts-card-desc-font-color-${highlightColor}">`,
        );
      }
      desc = desc.replace(/\^/g, '');

      // Line breaks
      desc = desc.replace(/\.\s/g, '.<br> ');

      // weird space symbol issues
      desc = desc.replace(/\u00a0/g, ' ');

      return desc;
    },
  },
  methods: {
    getImgUrl(url) {
      var images = require.context('../assets/', true, /\.png$/);
      return images('./' + url + '.png');
    },
    camelise(str) {
      str = str.split('.').join('');
      str = str.split(`'`).join('');
      return str
        .replace(/(?:^\w|[A-Z\.]|\b\w)/g, function(letter, index) {
          // old regex - `/(?:^\w|[A-Z\.]|\b\w)/g`
          return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
    // brokenName(name){
    //   let broken = false;
    //   let nameFix = name;
    //   if (name === 'tURBO') {
    //     broken = true;
    //     nameFix = 'turbo';
    //   }
    //   return {
    //     broken: broken,
    //     fix: nameFix,
    //   }
    // },
    getCardWidth() {
      // this.cardWidthPx = document.getElementByID('card-0').offsetWidth;
      // this.cardWidthPx = document.getElementsByClassName('base')[0].offsetWidth;
      // console.log(typeof elementIdForWidth === 'undefined');

      // setTimeout(() => {
      if (typeof this.elementIdForWidth === 'undefined') {
        // console.log(true);

        this.cardWidthPx = document.getElementsByClassName(
          'base',
        )[0].offsetWidth;
        return;
      }
      this.cardWidthPx = document.getElementById(
        this.elementIdForWidth,
      ).offsetWidth;
      // }, 30);
    },
    addCardToDeck(name, plus) {
      this.$emit('addCardInfo', name, plus);
    },
    upgradeCard() {
      this.$emit('upgradeCard', this.mydeckIndex);
    },
    downgradeCard() {
      this.$emit('downgradeCard', this.mydeckIndex);
    },
    removeCard() {
      this.$emit('removeCard', this.mydeckIndex);
    },
  },
  mounted() {
    window.addEventListener('resize', this.getCardWidth);
    this.getCardWidth();
    setTimeout(() => {
      this.getCardWidth();
    }, 50);
    setTimeout(() => {
      this.getCardWidth();
    }, 100);
    setTimeout(() => {
      this.getCardWidth();
    }, 200);
    setTimeout(() => {
      this.getCardWidth();
    }, 500);
    setTimeout(() => {
      this.getCardWidth();
    }, 2500);
  },
  updated() {
    this.getCardWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getCardWidth);
  },
};
</script>

<style lang="scss" scoped>
// Stroke funtion for mana text
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
    }
  }
  @return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}
.base {
  filter:drop-shadow( 0 0 4px rgba(0, 0, 0, 0.3));
}
.card {
  display: flex;
  align-items: center;
  // padding: 16px 5% 0;
  &:hover {
    .base {
      // box-shadow: 0 0 20px 15px rgba(#000000, 0.3);
      filter:drop-shadow( 0 5px 20px black);
    }
    .plus {
      display: block !important;
    }
    .card-button {
      display: flex;
    }
  }
}

.card1 {
  position: relative;
  width: 100%;
}

.mana {
  position: absolute;
  top: -5%;
  left: -8%;
  width: 27%;

  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    // width: 100%;
    transform: translate(-50%, -50%);

    font-size: 56px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    @include stroke(3, #4c4943);
  }
}

.cardimg {
  position: absolute;
  top: 10.1%;
  margin: 0.5%;
  left: 50%;
  transform: translateX(-50%);
  width: 82.4%;

  &only {
    top: 8%;
    position: relative;
  }
}

.frame {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;

  &only {
    top: -15%;
  }
}

.banner {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 106%;
  &only {
    top: -7%;
  }
}

// text
.title {
  position: absolute;
  top: 4.8%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 156%;
  font-size: 20px;
  // font-weight: 400;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  // -webkit-text-stroke: 2px #59564F;
  color: #fff6e1;
  text-shadow: 3px 3px 0 #59564f, 2px 2px 0 #59564f, -1px -1px 0 #59564f,
    1px -1px 0 #59564f, -1px 1px 0 #59564f, 1px 1px 0 #59564f;
  // paint-order: stroke fill;

  &-plus {
    color: #76f900;
  }
}

.tier {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 24%;
  width: 34%;
  position: absolute;
  top: 40%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: 1s;
  p {
    font-size: 24px;
    font-weight: bold;
    @include stroke(2, #4c4943);
  }
  &:hover {
  }
}
.tier-s {
  background-image: url('./../assets/cards/tier/s.svg');
}
.tier-a {
  background-image: url('./../assets/cards/tier/a.svg');
}
.tier-b {
  background-image: url('./../assets/cards/tier/b.svg');
}
.tier-c {
  background-image: url('./../assets/cards/tier/c.svg');
}
.tier-d {
  background-image: url('./../assets/cards/tier/d.svg');
}
.tier-e {
  background-image: url('./../assets/cards/tier/e.svg');
}
.tier-f {
  background-image: url('./../assets/cards/tier/f.svg');
}
.tier-u {
  background-image: url('./../assets/cards/tier/u.svg');
}

.description {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translateX(-50%);
  width: 72%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff6e1;
  text-shadow: 1px 1px 0 #1d1d18;

  > p {
    font-size: 34px;
    text-align: center;
    margin: 0;
    .gold {
      color: #f0c944;
    }
  }

  > img {
    display: inline;
  }
}

.type {
  position: absolute;
  top: 52.8%;
  left: 50%;
  transform: translateX(-50%);
  text-transform: capitalize;
  color: #595959;
  font-size: 24px;
  font-weight: 700;
  margin-left: 0.5%;
}

.plus {
  position: absolute;
  top: calc(-5% - 6px);
  right: calc(-8% - 6px);
  width: 27%;
  display: none;
  padding: 6px;
}

.plus2 {
  background-image: url('./../assets/ui/plusbg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.card-button {
  display: none;
  position: absolute;
  bottom: -4%;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  height: 16%;
  justify-content: center;
  cursor: pointer;
  > div {
    background: #000;
    width: 50%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;

    // padding-bottom: 8px;
    background: url('./../assets/ui/buttonL2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);

    display: flex;
    &:hover {
      transform: scale(1.08, 1.08);
      transition: 0s;
      z-index: 100;
      background: url('./../assets/ui/buttonHover2.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    > p {
      padding: 0;
      margin: 0 0 2px;
      font-size: 14px;
    }
  }
}

.compare-add {
  bottom: 10%;
}
</style>

<style lang="scss">
.sts-card-desc-font-color-gold {
  color: #f0c944;
}

.sts-card-desc-font-color-green {
  color: #76f900;
}

.sts-card-desc-font-color-red {
  color: #ff645f;
}

.sts-card-desc-mana-img {
  width: 17px;
  height: 17px;
  transform: translateY(2px) scale(1.2);
  display: inline;
}
</style>
